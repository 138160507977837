<template>
<div id="navbar" class="navbar navbar-default ace-save-state">
    <div class="navbar-container ace-save-state" id="navbar-container">
        <button type="button" class="navbar-toggle menu-toggler pull-left" id="menu-toggler" data-target="#sidebar">
            <span class="sr-only">Toggle sidebar</span>

            <span class="icon-bar"></span>

            <span class="icon-bar"></span>

            <span class="icon-bar"></span>
        </button>

        <div class="navbar-header pull-left">
            <router-link to="/" class="navbar-brand">
                <small>
                    <i class="fa fa-wifi"></i>
                    voX
                </small>
            </router-link>
        </div>

        <div class="navbar-buttons navbar-header pull-right" role="navigation">
            <ul class="nav ace-nav">
              <li class="grey dropdown-modal">
                    <router-link class="orange2 dropdown-toggle" to="/sistema/agenda?vencidas=true">
                        <i class="ace-icon fa fa-address-book-o"></i>
                        <span class="badge badge-grey">{{ totalVencidos }}</span>
                    </router-link>
                </li>
                <li class="green dropdown-modal">
                    <a data-toggle="dropdown" class="orange2 dropdown-toggle" href="#">
                        <i class="ace-icon fa fa-wrench"></i>
                        <span class="badge badge-success">{{ totalLogs }}</span>
                    </a>

                    <ul class="dropdown-menu-right dropdown-navbar dropdown-menu dropdown-caret dropdown-close">
                        <li class="dropdown-header">
                            <i class="ace-icon fa fa-check"></i>
                            {{ totalLogs }} Ações
                        </li>

                        <li class="dropdown-content">
                            <ul class="dropdown-menu dropdown-navbar">
                                <li v-for='(l, k) in Logs' :key="k">
                                    <a href="#">
                                        <div class="clearfix">
                                            <span class="pull-left">
                                              {{ l.created_at | dataHora }}
                                              <br />
                                                <span class="label" :class="l.description === 'cadastrado' ? 'label-success' : l.description === 'atualizado' ? 'label-info' : 'label-danger'">
                                                  {{ l.description }}
                                                </span>
                                              </span>
                                            <span class="pull-right">{{ l.log_name }}</span>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </li>

                        <li class="dropdown-footer" v-if="user.id_grupo == 1">
                            <router-link :to='{name: "Logs"}'>
                                Ver todas
                                <i class="ace-icon fa fa-arrow-right"></i>
                            </router-link>
                        </li>
                    </ul>
                </li>

                <li class="light-blue dropdown-modal">
                    <a data-toggle="dropdown" href="#" class="dropdown-toggle">
                        <span class="user-info">
                            <small>Bem-vindo!,</small>
                            {{ user.name }}
                        </span>

                        <i class="ace-icon fa fa-caret-down"></i>
                    </a>

                    <ul class="user-menu dropdown-menu-right dropdown-menu dropdown-yellow dropdown-caret dropdown-close">
                        <li>
                            <router-link to="/inicio/atualizacoes">
                                <i class="ace-icon fa fa-refresh"></i>
                                Atualizações
                            </router-link>
                        </li>

                        <li>
                            <router-link to="/inicio/minha-conta">
                                <i class="ace-icon fa fa-user"></i>
                                Minha Conta
                            </router-link>
                        </li>

                        <li class="divider"></li>

                        <li>
                            <a href="#" @click.prevent="sair">
                                <i class="ace-icon fa fa-power-off"></i>
                                Sair
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div><!-- /.navbar-container -->
</div>
</template>

<script>
import jwt from 'jwt-simple'
import moment from 'moment'
import {mapActions, mapGetters} from 'vuex'
moment.locale('pt-BR')

export default {
  filters: {
    dataHora (x) {
      return moment(x).format('L LT')
    }
  },
  computed: {
    ...mapGetters([
      'totalLogs',
      'Logs',
      'totalVencidos'
    ])
  },
  data () {
    return {
      user: false
    }
  },
  created () {
    let user = localStorage.getItem('user')
    if (user) {
      this.user = JSON.parse(jwt.decode(user, 'vox'))
      this.getUser(this.user)
      this.getLogsTotal(this.user.id)
      this.getTotalVencidos(this.user.id)
    } else {
      this.$router.push('/acessar')
    }
  },
  methods: {
    ...mapActions([
      'getUser',
      'getLogsTotal',
      'getTotalVencidos'
    ]),
    sair () {
      localStorage.clear()
      this.$router.push('/acessar')
    }
  }
}
</script>

<style scoped>

</style>
