<template>
<div class="no-skin">
    <Navbar />
    <div class="main-container ace-save-state" id="main-container">
        <Menu></Menu>
        <div class="main-content">
            <div class="main-content-inner">
                <Navegation />
                <div class="page-content">
                    <div class="row">
                        <div class="col-xs-12">
                            <!-- PAGE CONTENT BEGINS -->
                                <router-view/>
                            <!-- PAGE CONTENT ENDS -->
                        </div><!-- /.col -->
                    </div><!-- /.row -->
                </div><!-- /.page-content -->
            </div>
        </div><!-- /.main-content -->
        <Footer />
    </div><!-- /.main-container -->
  </div>
</template>

<script>
import jwt from 'jwt-simple'
import Menu from '../components/Menu'
import Navegation from '../components/Navegation'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

export default {
  name: 'Default',
  components: {
    Menu,
    Navbar,
    Navegation,
    Footer
  },
  data () {
    return {
      user: false
    }
  },
  created () {
    let user = localStorage.getItem('user')
    if (user) {
      this.user = JSON.parse(jwt.decode(user, 'vox'))
    } else {
      this.$router.push('/acessar')
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>
