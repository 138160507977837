<template>
<div id="sidebar" class="sidebar responsive ace-save-state">
  <ul class="nav nav-list">
      <li :class="{open : m.action || sub(m.submenu)}" v-for="m in menu" :key="m.titulo" >
          <a href="#" class="dropdown-toggle" @click.prevent="m.action = !m.action" v-if="m.visualizar">
              <i :class="m.icone"></i>
              <span class="menu-text">{{ m.titulo }}</span>

              <b class="arrow fa fa-angle-down"></b>
          </a>

          <b class="arrow"></b>

          <ul class="submenu">
              <li :class="{active : $route.meta.path === sub.url}" v-for="sub in m.submenu" :key="sub.titulo" >
                  <router-link :to="sub.url" v-if="sub.visualizar">
                      <i :class="sub.icone"></i> {{ sub.titulo }}
                  </router-link>
              </li>
          </ul>
      </li>
  </ul><!-- /.nav-list -->

  <div class="sidebar-toggle sidebar-collapse" id="sidebar-collapse">
      <i id="sidebar-toggle-icon" class="ace-icon fa fa-angle-double-left ace-save-state" data-icon1="ace-icon fa fa-angle-double-left" data-icon2="ace-icon fa fa-angle-double-right"></i>
  </div>
</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'Menu',
  computed: {
    ...mapGetters([
      'menu'
    ])
  },
  methods: {
    ...mapActions([
      'getMenu'
    ]),
    sub (item) {
      return item.filter(z => z.url === this.$route.meta.path).length
    }
  },
  mounted () {
    this.getMenu()
  }
}
</script>

<style scoped>

</style>
