<template>
<div class="breadcrumbs ace-save-state" id="breadcrumbs">
    <ul class="breadcrumb">
        <li v-for="(nav, key) in $route.meta.navigation" :key="key" :class="{active : nav.active}">
            <i :class="nav.class" v-if="nav.class"></i>
            <router-link :to="nav.url.replace(':id', $route.params.id)" v-if="nav.url">{{ nav.pagina }}</router-link>
            <span v-else>{{ nav.pagina }}</span>
        </li>
    </ul><!-- /.breadcrumb -->
</div>
</template>

<script>
export default {
  name: 'Navegation'
}
</script>
